/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    span: "span",
    h2: "h2",
    ul: "ul",
    li: "li",
    h3: "h3"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "At ", React.createElement(_components.a, {
    href: "/"
  }, "Yelowsoft"), ", our team is highly dedicated to achieve excellence in all the work that we do. We have achieved excellence in all our works in the past by continuously reinventing ourselves. This continuous process of reinvention has now become our trademark."), "\n", React.createElement("div", null, React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 800px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 56.49999999999999%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAECAwQF/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAeqpURuEV//EABoQAAIDAQEAAAAAAAAAAAAAAAABAgMREjH/2gAIAQEAAQUCskxd4TRVFYvP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAHBAAAQQDAQAAAAAAAAAAAAAAEQABEBICgZHB/9oACAEBAAY/AhieFWudQ6d/Y//EABoQAQADAQEBAAAAAAAAAAAAAAEAESExQVH/2gAIAQEAAT8hE139SSpwZmYaQtep2OZd39ThP//aAAwDAQACAAMAAAAQP8//xAAVEQEBAAAAAAAAAAAAAAAAAAAQQf/aAAgBAwEBPxCH/8QAFREBAQAAAAAAAAAAAAAAAAAAEEH/2gAIAQIBAT8Qp//EABsQAQEAAwEBAQAAAAAAAAAAAAERADFBIXGh/9oACAEBAAE/EAYBRngiz7rGCsaZfqeXm8UGTNASBDHneaMtdNioD8sz8mf/2Q=='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image lazyload\"\n        alt=\"yelowsoft-releases-new-main\"\n        title=\"\"\n        data-src=\"/static/199d5c1a818c0530ce5408d63ecfead7/a24e6/yelowsoft-releases-new-main.jpg\"\n        data-srcset=\"/static/199d5c1a818c0530ce5408d63ecfead7/a6b4f/yelowsoft-releases-new-main.jpg 200w,\n/static/199d5c1a818c0530ce5408d63ecfead7/a6407/yelowsoft-releases-new-main.jpg 400w,\n/static/199d5c1a818c0530ce5408d63ecfead7/a24e6/yelowsoft-releases-new-main.jpg 800w\"\n        sizes=\"(max-width: 800px) 100vw, 800px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), "\n", React.createElement(_components.p, null, "Continuing the same habit of reinvention and innovation we have now come up with all-new version update of our taxi solution. This newer version update makes our taxi solution fast, improved, efficient and advanced. So, what is this newer version all about? Let’s have a look."), "\n", React.createElement(_components.h2, {
    id: "why-did-we-move-to-the-newer-version-update"
  }, "Why did we move to the newer version update?"), "\n", React.createElement(_components.p, null, "The main reason for moving to the newer version update was to achieve faster speed and to make our solution more robust and advanced. The taxi solutions have to deal with a massive amount of data on a real-time basis. It’s because it receives, process, and sends a huge amount of data in real-time. If we talk in technical terms then the below three processes are always taking place simultaneously."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Real-time ingestion: – Receiving data in the real-time"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Real-time processing: – Processing the received data in real-time"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Real-time update: – Sending the processed data in real-time"), "\n"), "\n"), "\n", React.createElement(_components.p, null, "Let’s understand this process with an example. Suppose, the system receives a booking request. Now, the system has to find the nearest driver for that request. For that to happen the system requires the ", React.createElement(_components.a, {
    href: "/blog/real-time-tracking-management-system-with-websockets/"
  }, "real-time location"), " of all the drivers, this data received in real-time is what we call as real-time ingestion."), "\n", React.createElement(_components.p, null, "Now, once the system receives the request, it will process it to find the nearest driver which is known as real-time processing. Now, once the system finds the nearest driver, it will notify that driver about the request which will come under real-time update."), "\n", React.createElement(_components.p, null, "We had to come up with an update that can seamlessly manage all these data in real-time without compromising on the speed of the solution. To achieve this we made several changes which are going to discuss one by one."), "\n", React.createElement(_components.h2, {
    id: "microservices-architecture"
  }, "Microservices architecture"), "\n", React.createElement(_components.p, null, "In our newer version update, we have come up with microservices architecture. First, let’s understand what Microservices are?"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Microservices are tiny, independent, and loosely coupled. In this, every service is a separate codebase that can be seamlessly managed by a small team of developers."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Developers can deploy these services independently. This enables the team to update any existing service without redeploying or rebuilding the entire application."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "The communication between services takes place via well-defined APIs. However, the details of the     internal implementation of each service are kept hidden from the other services."), "\n"), "\n"), "\n", React.createElement(_components.p, null, "Now, that we have understood microservices, let’s have a look at what are its benefits."), "\n", React.createElement(_components.h3, null, "Smaller code base"), "\n", React.createElement(_components.p, null, "In the case of a monolithic application, the code dependencies become too tangled. Due to which, you need to require to touch code in a lot of places for adding a new feature. Microservices architecture minimizes these dependencies by not sharing the data stores or codes which ultimately makes it easy to add new ", React.createElement(_components.a, {
    href: "/features/"
  }, "features"), "."), "\n", React.createElement(_components.h3, null, "A mix of technology stacks"), "\n", React.createElement(_components.p, null, "The microservices architecture helps you pick the technology which is best suited for your service. You can also use a combination of the technology stack."), "\n", React.createElement(_components.h3, null, "Scalability"), "\n", React.createElement(_components.p, null, "Microservices are great when it comes to scalability as it enables you to scale services independently. With this, you can scale subsystems that need more resources without scaling the whole application."), "\n", React.createElement(_components.p, null, "We used microservices architecture in our newer update because it enabled us to divide work into multiple services such as:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "The driver services will take care of the work related to the drivers."), "\n", React.createElement(_components.li, null, "The auth services will take care of all the authentication work."), "\n", React.createElement(_components.li, null, "The matching service will match the ride request by finding the best-suited driver."), "\n"), "\n", React.createElement(_components.p, null, "With microservices architecture in place, we were able to identify the services which were the most occupied. Moreover, it also enabled us to enhance that particular services which in turn made the system faster."), "\n", React.createElement(_components.p, null, "This was a huge bonus for us as earlier in the monolithic architecture, we had to enhance the whole server to make enhancements in any of the particular services."), "\n", React.createElement(_components.h2, {
    id: "segmentation-of-data-as-per-the-use-case"
  }, "Segmentation of data as per the use case"), "\n", React.createElement(_components.p, null, "We segmented data as per their use case and ensured that they are stored separately. This separation of storage ensured that real-time operations don’t get affected when anyone generates a report from the data. As per the use case, we divided data into three categories:"), "\n", React.createElement(_components.h3, null, "Reporting data"), "\n", React.createElement(_components.p, null, "We used the reporting database and ensured that all the reporting data is stored in the reporting database itself."), "\n", React.createElement(_components.h3, null, "Streaming data"), "\n", React.createElement(_components.p, null, "To store streaming data we have used the streaming database as there is a continuous stream of data that we were dealing with. We used Kafka for the streaming database which enables streaming of driver’s location and trips."), "\n", React.createElement(_components.h3, null, "Master data"), "\n", React.createElement(_components.p, null, "We kept master data into the master database. We kept such data in this database which is not meant to lose such as driver information."), "\n", React.createElement(_components.h2, {
    id: "switched-from-python-to-nodejs"
  }, "Switched from Python to Node.js"), "\n", React.createElement(_components.p, null, "Before making the decision to move to Node.js, we compared both Python and Node.js and found out that Node.js had more support as it was JavaScript. Apart from this, the main advantage that Node.js had over Python was the fact that it is asynchronous."), "\n", React.createElement(_components.p, null, "Asynchronous processing is a must-have for all real-time applications especially for something like a taxi application where a massive amount of data has to be executed in real-time. Let’s understand what asynchronous processing is with a simple example."), "\n", React.createElement("div", null, React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 800px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 56.49999999999999%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAECAwQF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB7VOmIxh//8QAGhAAAgMBAQAAAAAAAAAAAAAAAQIAAxEQIf/aAAgBAQABBQInyu1bIDsyBFXn/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGhABAAEFAAAAAAAAAAAAAAAAARAAEiEiYf/aAAgBAQAGPwJ5TauJ1Aj/xAAaEAEBAQEBAQEAAAAAAAAAAAABEQAxIUGB/9oACAEBAAE/ITF9D2szlD6poKX90Iid7rPqdhgnN//aAAwDAQACAAMAAAAQA8//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAVEQEBAAAAAAAAAAAAAAAAAAAAAf/aAAgBAgEBPxBH/8QAGhABAAMBAQEAAAAAAAAAAAAAAQARIVExQf/aAAgBAQABPxByJmhpV52YVkQp7zYVQC6xUTBQ0H6RJeKpFwBQo4T/2Q=='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image lazyload\"\n        alt=\"yelowsoft-releases-new-switch.jpg\"\n        title=\"\"\n        data-src=\"/static/2fd59f484aefe606a0fdfe955655a5e4/a24e6/yelowsoft-releases-new-switch.jpg\"\n        data-srcset=\"/static/2fd59f484aefe606a0fdfe955655a5e4/a6b4f/yelowsoft-releases-new-switch.jpg 200w,\n/static/2fd59f484aefe606a0fdfe955655a5e4/a6407/yelowsoft-releases-new-switch.jpg 400w,\n/static/2fd59f484aefe606a0fdfe955655a5e4/a24e6/yelowsoft-releases-new-switch.jpg 800w\"\n        sizes=\"(max-width: 800px) 100vw, 800px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), "\n", React.createElement(_components.p, null, "Let assume that you’re on standing the top of a mountain with 1000 balls. And you have to push all those 1000 balls at the bottom of the mountain in the least possible time. Now, here it’s quite obvious that you can’t push all those 1000 balls at once. So, you’re only left with two options."), "\n", React.createElement(_components.p, null, "The first option is to push them one by one. In this, you can push one ball and wait for the ball to reach at the bottom to push the next one. However, with this technique, you will take a long time to finish your task."), "\n", React.createElement(_components.p, null, "Now there’s a second option, in which you can push balls one by one without having to wait for them to reach the bottom. With this technique, you can push all those 1000 balls in the least time possible."), "\n", React.createElement(_components.p, null, "In this example, the first technique is the synchronous execution and the second one is asynchronous. The above-mentioned hypothetical example makes it clear that the asynchronous execution is faster than the synchronous execution."), "\n", React.createElement(_components.p, null, "Now, let’s see how asynchronous execution helps to boost the server performance?"), "\n", React.createElement(_components.p, null, "Assume that a ball in the above example is equivalent to one query to the database. So, whenever process data in synchronous execution for a massive project that has many aggregations, queries, etc. then it would simply block the code execution."), "\n", React.createElement(_components.p, null, "However, if you’re processing it in asynchronous execution, then you can just execute all the queries at once and then collect the results afterwards."), "\n", React.createElement(_components.h2, {
    id: "caching-layer"
  }, "Caching layer"), "\n", React.createElement(_components.p, null, "We also added a caching layer where we decided to put data which is frequently used in real-time processes like finding the nearest driver and many other processes. This enabled us to collect the data which is used in real-time processes directly from the caching layer thus eliminating the need to go the database."), "\n", React.createElement(_components.p, null, "The addition of the caching layer helped us to make our system faster for many processes as less amount of time was consumed for retrieving data from the caching layer as compared to that of the database."), "\n", React.createElement(_components.p, null, "Moreover, we also ensured that every time there’s an update in the database, the caching layer should also get updated. To ensure this we carried out several tests in numerous scenarios and finally succeed in achieving our goal."), "\n", React.createElement(_components.p, null, "We also ensured that our system is not totally dependent on the caching layer. In case our caching layer goes down then the system would still be working and it would fetch all the required data from the database to the caching layer automatically."), "\n", React.createElement(_components.h2, {
    id: "app-level"
  }, "App level"), "\n", React.createElement(_components.p, null, "The above points which we mentioned were from the server-side. However, we also had to make major changes from the App side as well. So, let’s discuss these changes one by one."), "\n", React.createElement(_components.h3, null, "Lower battery consumption"), "\n", React.createElement(_components.p, null, "We made our application light by using lightweight servers which made the connections lighter. We also use code optimization for making our solution light. Secondly, we divided data into several segments, so the system can take the required segment of data instead of taking the whole bunch of data. By this way, we were able to avoid the multiple API calls."), "\n", React.createElement(_components.h3, null, "Low network usage"), "\n", React.createElement(_components.p, null, "Apart from lowering the battery consumption, the lightweight of our application also enhanced its performance in the low network."), "\n", React.createElement(_components.h3, null, "Making the mobile application faster"), "\n", React.createElement(_components.p, null, "To make the mobile application faster, we decided to get only what was needed instead of getting the entire bunch of objects. Moreover, we also decided to cache the recurring object and took it from the local cache of mobile rather than going to the server which boosted the performance of our application."), "\n", React.createElement(_components.p, null, "This was all about the new version update of Yelowsoft’s taxi solution. We’ll be coming up with lots of more product updates in near future, till then keep reading this space."), "\n", React.createElement("div", null, React.createElement(_components.a, {
    href: "/contact/"
  }, React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 800px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 20%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAEABQDASIAAhEBAxEB/8QAFwABAAMAAAAAAAAAAAAAAAAAAAMEBf/EABUBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAHVmBpAr//EABkQAAIDAQAAAAAAAAAAAAAAAAECAwQRMf/aAAgBAQABBQJNLHlZiYv/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAZEAACAwEAAAAAAAAAAAAAAAAAAQIRIUH/2gAIAQEABj8ClbGa+n//xAAXEAADAQAAAAAAAAAAAAAAAAABESEA/9oACAEBAAE/ITZBA0qkzcyhv//aAAwDAQACAAMAAAAQ/D//xAAXEQADAQAAAAAAAAAAAAAAAAAAASFB/9oACAEDAQE/ENGqf//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABkQAQADAQEAAAAAAAAAAAAAAAEAESFRYf/aAAgBAQABPxA0YMMK3yC81WR5L4EAXcJ//9k='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image lazyload\"\n        alt=\"yelowsoft-releases-new-cta3\"\n        title=\"\"\n        data-src=\"/static/3f4ca291bd9d5ab399fb031535794953/a24e6/yelowsoft-releases-new-cta3.jpg\"\n        data-srcset=\"/static/3f4ca291bd9d5ab399fb031535794953/a6b4f/yelowsoft-releases-new-cta3.jpg 200w,\n/static/3f4ca291bd9d5ab399fb031535794953/a6407/yelowsoft-releases-new-cta3.jpg 400w,\n/static/3f4ca291bd9d5ab399fb031535794953/a24e6/yelowsoft-releases-new-cta3.jpg 800w\"\n        sizes=\"(max-width: 800px) 100vw, 800px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  }))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
